import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import ScrollRestoration from "./components/ScrollRestoration/ScrollRestoration";
import { AnimatePresence } from 'framer-motion';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Resume from './pages/Resume/Resume';
import Grihabhoomi from './pages/Work/projects/Grihabhoomi';
import Work from './pages/Work/Work';
import Khwappa from './pages/Work/projects/Khwappa';
import SuprimUiComp from './pages/Work/projects/SuprimUiComp';
import Duall from './pages/Work/projects/duall';
import ShareLook from './pages/Work/projects/sharelook';

function App() {
  return (
    <>
      <Router>
        <Route
          render={({ location }) => (
            <>
              <AnimatePresence initial={false} exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  <Route exact path='/' render={() => <Home />}></Route>
                  <Route exact path='/resume' render={() => <Resume />}></Route>
                  <Route exact path='/work' render={() => <Work />}></Route>
                  <Route
                    exact
                    path='/work/share-look'
                    component={ShareLook}></Route>
                  <Route
                    exact
                    path='/work/griha-bhoomi'
                    component={Grihabhoomi}></Route>
                  <Route
                    exact
                    path='/work/khwappa-cartoons'
                    component={Khwappa}></Route>
                  <Route
                    exact
                    path='/work/suprim-ui'
                    component={SuprimUiComp}></Route>
                  <Route exact path='/work/duall' component={Duall}></Route>
                  <Route
                    exact
                    path='/contact'
                    render={() => <Contact></Contact>}></Route>
                </Switch>
              </AnimatePresence>
              {/* <ScrollRestoration></ScrollRestoration> */}
            </>
          )}></Route>
      </Router>
    </>
  );
}

export default App;
