import PageLayout from '../../components/Hoc/PageLayout';
// import SmoothScroll from "../../components/SmoothScroll/SmoothScroll";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// import SiteFooter from "../../components/Layouts/SiteFooter";
const Work = () => {
  return (
    <PageLayout>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='bg-gradient'>
        {/* <SmoothScroll> */}
        <section className='work sp'>
          <div className='wrapper'>
            <div className='work-lists'>
              <div className='work-item'>
                <div className='work-item--img'>
                  <Link to='/work/share-look' className='link'>
                    <img src='/assets/img/sharelook/intro.png' alt='' />
                  </Link>
                </div>
                <div className='work-item--title'>
                  <h3>ShareLook</h3>
                </div>
              </div>
              <div className='work-item'>
                <div className='work-item--img'>
                  <Link to='/work/griha-bhoomi' className='link'>
                    <img src='/assets/img/slide/1.jpg' alt='' />
                  </Link>
                </div>
                <div className='work-item--title'>
                  <h3>Griha Bhoomi</h3>
                </div>
              </div>
              <div className='work-item'>
                <div className='work-item--img'>
                  <Link to='/work/khwappa-cartoons' className='link'>
                    <img src='/assets/img/slide/2.jpg' alt='#' />
                  </Link>
                </div>
                <div className='work-item--title'>
                  <h3>Khaappa Cartoons</h3>
                </div>
              </div>
              <div className='work-item'>
                <div className='work-item--img'>
                  <Link to='/work/suprim-ui' className='link'>
                    <img src='/assets/img/slide/3.jpg' alt='#' />
                  </Link>
                </div>
                <div className='work-item--title'>
                  <h3>Suprim UI</h3>
                </div>
              </div>
              <div className='work-item'>
                <div className='work-item--img'>
                  <Link to='/work/duall' className='link'>
                    <img src='/assets/img/slide/dulla-intro.jpg' alt='#' />
                  </Link>
                </div>
                <div className='work-item--title'>
                  <h3>Dulla Studio</h3>
                </div>
              </div>
              <div className='work-item'>
                <div className='flex-al-i-c flex-ju-c-c '>
                  <h4>More will be added soon.</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <SiteFooter /> */}
        {/* </SmoothScroll> */}
      </motion.main>
    </PageLayout>
  );
};

export default Work;
