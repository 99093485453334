import { NavLink,Link } from 'react-router-dom';
import useSound from 'use-sound';

const SiteHeader = () => {
  const [play, { stop }] = useSound('/assets/sounds/hover1.mp3');
  return (
    <header className='site-header '>
      <nav className='site-nav fixed'>
        <div className='wrapper'>
          <Link to='/' className='site-logo'>
            <img
              src='/assets/img/logo.svg'
              className='site-logo-img'
              alt='logo'></img>
          </Link>
          <div className='site-links-wrapper ml-auto'>
            <ul className='site-links'>
              <li onMouseEnter={play} onMouseLeave={stop}>
                <NavLink className='site-link' to='/work'>
                  WORK
                </NavLink>
              </li>
              <li onMouseEnter={play} onMouseLeave={stop}>
                <NavLink className='site-link' to='/resume'>
                  RESUME
                </NavLink>
              </li>
              {/* <li onMouseEnter={play} onMouseLeave={stop}>
                <Link className="site-link" to="/contact">
                  CONTACT
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SiteHeader;
