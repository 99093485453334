import { motion } from "framer-motion";
import PageLayout from "../../components/Hoc/PageLayout";

const Contact = () => {
  return (
    <PageLayout>
      <motion.section exit={{ opacity: 0 }} className="resume-intro">
        <div className="wrapper">
          <h3>Redesign comming soon.</h3>
        </div>
      </motion.section>
    </PageLayout>
  );
};

export default Contact;
