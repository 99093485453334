import { useEffect } from "react";
import gsap from "gsap";
const BgStars = () => {
  useEffect(() => {
    function dustLoop() {
      if (document.querySelector(".su-star")) {
        gsap.timeline({ onComplete: dustLoop }).set(
          ".d",
          {
            x: () => window.innerWidth * 2 * Math.random(), // overshoot width+height of su-star to reduce number visible at once
            y: () => window.innerHeight * 2 * Math.random(), //
            rotation: () => 360 * Math.random(),
            scale: () => Math.random(),
            opacity: () => Math.random(),
          },
          0.08
        );
      }
    }
    if (document.querySelector(".su-star")) {
      gsap.set(".su-star", {
        delay: 0.5,
        perspective: 800,
        y: "0",
        visibility: "visible",
        opacity: 1,
      });
      for (let i = 0; i < 8; i++) {
        let d = document.createElement("div");
        document.getElementsByClassName("su-star")[0].appendChild(d);
        gsap.set(d, {
          attr: { class: "d" },
          width: 30,
          height: 30,
          backgroundImage: "url(/assets/img/filmDust.png)",
          backgroundPosition: "0 -" + gsap.utils.wrap(0, 8, i) * 30 + "px",
        });
      }
      requestAnimationFrame(dustLoop);
    }
    // gsap.set(".grain", {
    //   width: "100%",
    //   height: "100%",
    //   backgroundImage: "url(./img/grain.png)",
    //   mixBlendMode: "overlay",
    // });

    return () => {
      cancelAnimationFrame(dustLoop);
    };
    // dustLoop();
  }, []);
  return (
    <>
      <div className="su-star">{/* <div className="grain"></div> */}</div>
    </>
  );
};

export default BgStars;
