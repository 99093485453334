import { createContext, useReducer, useEffect, useCallback } from "react";
import { UiReducer } from "./UiReducer";
import * as types from "../types";
export const UiContext = createContext();
const initialState = {
  preload: false,
  isMobile: false,
  playAudio: true,
  audioUrl: "",
};

export const UiContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UiReducer, initialState);

  const ui_preloaded = () => {
    dispatch({
      type: types.Preloaded,
    });
  };

  function isMobileDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
  let isMobile = isMobileDevice();

  const is_mobile = useCallback(() => {
    dispatch({
      type: types.IsMobile,
      payload: isMobile,
    });
  }, [dispatch, isMobile]);

  const toggle_audio = () => {
    dispatch({
      type: types.ToggleAudio,
    });
  };

  useEffect(() => {
    if (isMobile) {
      is_mobile();
      document.body.classList.add("ismobile");
    }
  }, [is_mobile, isMobile]);

  return (
    <UiContext.Provider
      value={{ state, ui_preloaded, is_mobile, toggle_audio }}
    >
      {children}
    </UiContext.Provider>
  );
};
