import * as types from "../types";

export const UiReducer = (state, action) => {
  switch (action.type) {
    case types.Preloaded:
      return {
        ...state,
        preload: true,
      };
    case types.ToggleAudio:
      return {
        ...state,
        playAudio: !state.playAudio,
      };
    case types.IsMobile:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};
