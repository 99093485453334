import { useEffect, useState } from "react";
import SiteHeader from "../Layouts/SiteHeader";
import Preloader from "../Preloader/Preloader";
import SmoothCursor from "../SmoothCursor/SmoothCursor";
import PageTrans from "../PageTrans/PageTrans";

const HomePageLayout = ({ children }) => {
  const [showcursor, setShowcursor] = useState(false);

  function isMobileDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
  let isMobile = isMobileDevice();
  useEffect(() => {
    setShowcursor(isMobile);
  }, [isMobile]);

  return (
    <main className="page-content">
      <SiteHeader />
      {children}
      <Preloader></Preloader>
      {!showcursor ? <SmoothCursor></SmoothCursor> : null}
      <PageTrans d="M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z"></PageTrans>
    </main>
  );
};

export default HomePageLayout;
