// import * as THREE from 'three';
// import gsap from 'gsap';
import { motion } from 'framer-motion';
import { useContext, useEffect, lazy, Suspense, useState } from 'react';
import useSound from 'use-sound';
// import { Link } from 'react-router-dom';
import HomePageLayout from '../../components/Hoc/HomePageLayout';
import { UiContext } from '../../core/ui-context/UiContext';
// import ThreeCanvas from "../../components/r3h/ThreeCanvas";
const WebglSlider = lazy(() => import('./components/WebglSlider'));

// import WebglSlider from './components/WebglSlider';
// import SmoothScroll from "../../components/SmoothScroll/SmoothScroll";
// import imagesLoaded from 'imagesloaded';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
// import SiteFooter from "../../components/Layouts/SiteFooter";
// const OrbitControls = require("three-orbit-controls")(THREE);

const Home = () => {
  const { toggle_audio, state } = useContext(UiContext);
  const [hoverplay, { stop: hoverstop }] = useSound(
    '/assets/sounds/hover1.mp3'
  );
  const handleaudiotoggle = () => {
    toggle_audio();
  };

  const [play, { stop, isPlaying }] = useSound('/assets/sounds/ambience.mp3', {
    volume: 0.5,
    interrupt: true,
    soundEnabled: state.playAudio,
    loop: true,
  });

  useEffect(() => {
    if (state.playAudio === false) {
      stop();
    } else {
      if (state.preload) {
        play();
      }
    }
    return () => {
      stop();
    };
  }, [state.playAudio, state.preload, isPlaying, stop, play]);

  // eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  return (
    <>
      <HomePageLayout>
        {/* <SmoothScroll> */}
        {isMobile && (
          <section className='hero'>
            <div className='wrapper'>
              <motion.div exit={{ opacity: 0 }} className='hero--content'>
                <div className='hero--intro'>
                  <div className='line'>
                    <h1>Hey there,</h1>
                  </div>

                  <div className='line'>
                    <h1>I'm a Full Stack Developer,</h1>
                  </div>
                  <div className='line'>
                    <h1>Based in Nepal.</h1>
                  </div>
                </div>
                <div className='hero--desc'>
                  <p>
                    I'm an ambitious, problem-solving full-stack developer
                    focused on crafting polished user experiences. I believe
                    great design and development are defined by the experience
                    it creates for user, combined with a flawless execution.
                  </p>
                </div>
              </motion.div>

              <div className='hero--footer'>
                <div className='hero--footer-content'>
                  <div className='hero--footer-item'>
                    <p as='p' className='sub'>
                      Need Help ?
                    </p>
                    <p className='sub-item'>suprimtech@gmail.com</p>
                  </div>
                  <div className='hero--footer-item'>
                    <p as='p' className='sub'>
                      Feel Like Talking
                    </p>
                    <p className='sub-item'>+977 9818728744</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {!isMobile && (
          <Suspense fallback={<div>Loading...</div>}>
            <div className='custom-sliderwrapper'>
              <WebglSlider />
            </div>
          </Suspense>
        )}
        {/* <SiteFooter /> */}
        {/* </SmoothScroll> */}
        <section className='footer-music'>
          <div className='wrapper'>
            <div
              className={`music--btn ${isPlaying ? 'active' : ''} `}
              onMouseEnter={hoverplay}
              onMouseLeave={hoverstop}>
              <button
                type='button'
                className='player'
                onClick={handleaudiotoggle}>
                <span className='center'></span>
                <span className='pulse'></span>
                <span className='pulse2'></span>
              </button>
            </div>
            {!isMobile && (
              <div className='su-slider--nav'>
                <p>Drag / Scroll </p>
              </div>
            )}
            {/* <div className='su-slider--nav'>
              <button
                type='button'
                onMouseEnter={hoverplay}
                onMouseLeave={hoverstop}
                className='su-slider--nav-prev'
                disabled>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='site-link'>
                  <line x1='19' y1='12' x2='5' y2='12'></line>
                  <polyline points='12 19 5 12 12 5'></polyline>
                </svg>
              </button>
              <button
                type='button'
                onMouseEnter={hoverplay}
                onMouseLeave={hoverstop}
                className='su-slider--nav-next'
                disabled>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='site-link'>
                  <line x1='5' y1='12' x2='19' y2='12'></line>
                  <polyline points='12 5 19 12 12 19'></polyline>
                </svg>
              </button>
            </div> */}
          </div>
        </section>
        {/* <div className="fiber">
          <ThreeCanvas></ThreeCanvas>
        </div> */}
      </HomePageLayout>
    </>
  );
};

export default Home;
