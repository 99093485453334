import { useEffect, useContext } from 'react';
import useSound from 'use-sound';
import PageLayout from '../../../components/Hoc/PageLayout';

import { UiContext } from '../../../core/ui-context/UiContext';
const ShareLook = () => {
  const { toggle_audio, state } = useContext(UiContext);
  const [hoverplay, { stop: hoverstop }] = useSound(
    '/assets/sounds/hover1.mp3'
  );
  const handleaudiotoggle = () => {
    toggle_audio();
  };

  const [play, { stop, isPlaying }] = useSound('/assets/sounds/ambience.mp3', {
    volume: 0.5,
    interrupt: true,
    soundEnabled: state.playAudio,
    loop: true,
  });

  useEffect(() => {
    if (state.playAudio === false) {
      stop();
    } else {
      if (state.preload) {
        play();
      }
    }
    return () => {
      stop();
    };
  }, [state.playAudio, state.preload, isPlaying, stop, play]);
  return (
    <PageLayout>
      <main exit={{ opacity: 0 }} className='resume-content'>
        {/* <SmoothScroll> */}
        <section className='work-detail--intro sp'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-6'>
                <h2>Sharelook </h2>
                <p>
                  Is there a life after live? We all know just how easy it is to
                  lose important communications when a virtual session
                  terminates. Video recording live events is easy with
                  ShareLook. You can also archive and retrieve these recordings,
                  as well as creating a course out of them. We provide virtual
                  solutions for everything, including e-learning, live
                  conferences, and live events — all from a simple and intuitive
                  user interface.
                </p>
                <a
                  className='site-link'
                  href='https://www.sharelookapp.com/'
                  rel='noreferrer'
                  target='_blank'>
                  Visit Site
                </a>
              </div>
              <div className='wrap-lg-3'>
                <h2>Position</h2>
                <p>Full Stack Development</p>
                <div className='spacer'></div>
                <div className='spacer'></div>
                <h2>Date</h2>
                <p>2020</p>
              </div>
              <div className='wrap-lg-3'>
                <h2>Technologies</h2>
                <ul>
                  <li>
                    <sub>01</sub>
                    React, Typescript
                  </li>
                  <li>
                    <sub>02</sub>
                    CSS (SASS)
                  </li>
                  <li>
                    <sub>03</sub>
                    Strapi (CMS)
                  </li>
                  <li>
                    <sub>03</sub>
                    NodeJs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='work-detail--fluid'>
          <img src='/assets/img/sharelook/intro.png' alt='' />
        </section>
        <section className='work-detail--content'>
          <div className='wrapper'>
            <figure>
              <figcaption>Home</figcaption>
              <img src='/assets/img/sharelook/home.png' alt='sharelook' />
            </figure>
            <figure>
              <figcaption>About</figcaption>
              <img src='/assets/img/sharelook/about.png' alt='about' />
            </figure>
            <figure>
              <figcaption>Features</figcaption>
              <img src='/assets/img/sharelook/features.png' alt='features' />
            </figure>
            <figure>
              <figcaption>Marketplace</figcaption>
              <img
                src='/assets/img/sharelook/marketplace.png'
                alt='marketplace'
              />
            </figure>
          </div>
        </section>
        {/* </SmoothScroll> */}
      </main>
      <section className='footer-music'>
        <div className='wrapper'>
          <div
            className={`music--btn ${isPlaying ? 'active' : ''} `}
            onMouseEnter={hoverplay}
            onMouseLeave={hoverstop}>
            <button
              type='button'
              className='player'
              onClick={handleaudiotoggle}>
              <span className='center'></span>
              <span className='pulse'></span>
              <span className='pulse2'></span>
            </button>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default ShareLook;
