import { useContext, useEffect } from 'react';
import useSound from 'use-sound';
import { motion } from 'framer-motion';
import PageLayout from '../../components/Hoc/PageLayout';
import { UiContext } from '../../core/ui-context/UiContext';

const Resume = () => {
  const { state } = useContext(UiContext);

  const [play, { stop, isPlaying }] = useSound(
    '/assets/sounds/underwater.mp3',
    {
      volume: 0.5,
      interrupt: true,
      soundEnabled: state.playAudio,
      loop: true,
    }
  );
  useEffect(() => {
    if (state.playAudio === false) {
      stop();
    } else {
      if (state.preload) {
        play();
      }
    }
    return () => {
      stop();
    };
  }, [state.playAudio, state.preload, isPlaying, stop, play]);

  return (
    <PageLayout>
      <motion.main exit={{ opacity: 0 }} className='resume-content'>
        {/* <SmoothScroll> */}
        <section className='resume-intro'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-8'>
                <h1 className='name-txt'>Suprim Golay</h1>
                <span className='breaker'></span>
                <h2>FULL Stack Developer </h2>
              </div>
              <div className='wrap-lg-4 resume-intro--right'>
                <p>
                  <a
                    className='link txt-link'
                    href='http://suprimgolay.com.np'
                    target='_blank'
                    rel='noopener noreferrer'>
                    suprimgolay.com.np
                  </a>
                </p>
                <p>
                  <a
                    className='link txt-link'
                    href='mailto:suprimtech@gmail.com'
                    target='_blank'
                    rel='noopener noreferrer'>
                    suprimtech@gmail.com
                  </a>{' '}
                </p>
                <p>
                  <a
                    className='link txt-link'
                    href=' https://github.com/suprim12'
                    target='_blank'
                    rel='noopener noreferrer'>
                    github.com/suprim12
                  </a>
                </p>
                <p>Adelaide, Australia </p>
              </div>
            </div>
          </div>
        </section>
        <section className='resume-item profile'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-3 wrap-12'>
                <h2 className='resume-title'>Profile</h2>
              </div>
              <div className='wrap-lg-9 wrap-12 profile-right'>
                <p>
                  I'm an ambitious, problem-solving full-stack developer looking
                  to join an organization. For me, everything started with a
                  passion for web technologies. Dedicated and efficient full
                  stack developer with 5+ years experience in application
                  layers, presentation layers, and databases. Always seeking
                  opportunities that enable me to grow as an individual.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='resume-item skills'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-3 wrap-10'>
                <h2 className='resume-title'>Technical Skills</h2>
              </div>
              <div className='wrap-lg-9 wrap-12'>
                <div className='skills-item'>
                  <div className='wrapper-row'>
                    <div className='wrap-lg-6 wrap-12'>
                      <p>Frontend Skills</p>
                      <ul className='list-unstyled'>
                        <li>Javascript (React, Nextjs), Typescript</li>
                        <li>HTML, Templating Languages (pug, handlebars) </li>
                        <li>CSS Methodologies & CSS Preprocessors(Sass)</li>
                        <li>Progressive Web Apps (PWA) & SEO</li>
                        <li>Webgl, Threejs, Gsap</li>
                        <li>
                          Version Control (Github) & Project management(Jira)
                        </li>
                      </ul>
                    </div>
                    <div className='wrap-lg-6 wrap-12'>
                      <p>Backend Skills + DevOps</p>
                      <ul className='list-unstyled'>
                        <li>NodeJS (REST API, GraphQl), NestJs</li>
                        <li>Python ( Django, DRF REST Framework, FastApi)</li>
                        <li>WordPress, Strapi CMS</li>
                        <li>
                          Databases - SQL(Mysql,PostgreSQL), NoSQL(mongodb)
                        </li>
                        <li>Docker, Kubernetes , AWS CI/CD</li>
                      </ul>
                    </div>
                    <div className='wrap-lg-12 wrap-12 mt-1'>
                      <p>Experience With Software</p>
                      <ul className='list-unstyled'>
                        <li>
                          Burp Suite & Kali Linux (OWASP Security Testing)
                        </li>
                        <li>
                          Adobe Illustrator
                          <ul className='list-unstyled'>
                            <li>Icon Design</li>
                            <li>Graphic Design</li>
                            <li>Illustration</li>
                          </ul>
                        </li>
                        <li>
                          Adobe XD CC / Figma
                          <ul>
                            <li>Website Design</li>
                            <li>App Design</li>
                          </ul>
                        </li>
                        <li>Photopshop-Intermediate</li>
                        <li>Canva, Crello - Banner, Post & Marketing Design</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='resume-item experience'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-3 wrap-10'>
                <h2 className='resume-title'>Experience</h2>
              </div>
              <div className='wrap-lg-9 wrap-12'>
                <div className='experience-item'>
                  <h3>Full Stack Developer / DevOps</h3>
                  <p>
                    <a
                      className='link txt-link'
                      rel='noopener noreferrer'
                      href='https://heubert.com/'
                      target='_blank'>
                      Heubert Pvt. Ltd
                    </a>{' '}
                    Maitidevi, Bhaneswor
                  </p>
                  <p>2021 - Current</p>
                  <ul>
                    <li>
                      Worked on SaaS app based on multi-tenant architecture with
                      Ant Design.
                    </li>
                    <li>
                      Continuously work on feature/performance enhancements and
                      monitoring of application after deployment to production.
                    </li>
                    <li>
                      Monorepo and TDD development practices with SOLID
                      principle, Multitenant Architecture
                    </li>
                    <li>
                      Dockerize apps, working with Kubernetes, pipelines and
                      environments.
                    </li>
                  </ul>
                </div>
                <div className='experience-item'>
                  <h3>Software Engineer</h3>
                  <p>
                    <a
                      className='link txt-link'
                      rel='noopener noreferrer'
                      href='http://wildboartech.com/'
                      target='_blank'>
                      Wild Boar Tech Pvt. Ltd
                    </a>{' '}
                    Budhanilkantha
                  </p>
                  <p>2019 - 2021</p>
                  <ul>
                    <li>
                      Write modern, performant, and robust code for a diverse
                      array of client and internal projects.
                    </li>
                    <li>
                      Continuously work on feature/performance enhancements and
                      monitoring of application after deployment to production.
                    </li>
                    <li>
                      Worked with a team of designers to build a marketing
                      websites and e-commerce platform as well as Working on
                      Mern Application.
                    </li>
                    <li>
                      Integrating ForntEnd with Django Rest Framework as well
                      focusing as Devops (Docker - load balancing, github
                      actions)
                    </li>
                  </ul>
                </div>
                <div className='experience-item'>
                  <h3>Software Developer </h3>
                  <p>
                    <a
                      className='link txt-link'
                      rel='noopener noreferrer'
                      href='http://www.everestsofttech.com/'
                      target='_blank'>
                      Everest Soft Tech Pvt. Ltd
                    </a>{' '}
                    Golfutar, Budhanilkantha
                  </p>
                  <p>2018 - 2019</p>
                  <ul>
                    <li>
                      Working with software like Namecheap, cPanel, Vultr,
                      CloudFlare.
                    </li>
                    <li>
                      Developing WordPress Site and Customization. Using
                      Photoshop for thumbnail design etc.
                    </li>
                    <li>
                      Mostly site are built for marketing purposes like
                      backlinks and ads.
                    </li>
                  </ul>
                </div>
                <div className='experience-item'>
                  <h3>Web Developer & Desginer</h3>
                  <p>
                    <a
                      className='link txt-link'
                      href='http://www.itnti.com'
                      rel='noopener noreferrer'
                      target='_blank'>
                      ITNTI Pvt.Ltd
                    </a>{' '}
                    Kathmandu, Sukhdhara, Chabhail
                  </p>
                  <p>2015 - 2018</p>
                  <ul>
                    <li>Icon design, Illustration, website design.</li>
                    <li>
                      Ensure Responsiveness, Maintenance, Testing & Debugging
                      Site.
                    </li>
                    <li>
                      Designed and built Custom Wordpress theme from scratch or
                      customized them depending on the clients needs.
                    </li>
                    <li>
                      Meeting clients to gather requirements, design sites and
                      outline schedules.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='resume-item education'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-3 wrap-12'>
                <h2 className='resume-title'>Education</h2>
              </div>
              <div className='wrap-lg-9 wrap-12'>
                <div className='education-item'>
                  <h3>
                    Bachelors of Science in Computer Science and Information
                    Technology.
                  </h3>
                  <p>
                    <a
                      className='link txt-link'
                      href='https://amritcampus.edu.np/'
                      rel='noopener noreferrer'
                      target='_blank'>
                      Amrit Science Campaus (ASCOL)-Kathmandu, Lainchor
                    </a>
                  </p>
                  <p>2014-2019</p>
                </div>
                <div className='education-item'>
                  <h3>Science Course +12</h3>
                  <p>
                    <a className='link' href='/' rel='noopener noreferrer'>
                      National School of Science (NIST) - Kathmandu, Lainchor
                    </a>
                  </p>
                  <p>2012-2014</p>
                </div>
                <div className='education-item'>
                  <h3>School Leaving Certificate (SLC) </h3>
                  <p>
                    <a
                      className='link txt-link'
                      rel='noopener noreferrer'
                      href='http://las.edu.np/'
                      target='_blank'>
                      Little Angle's School (LAS) Hattiban, Lalitpur
                    </a>
                  </p>
                  <p>2005-2012</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='resume-item interests'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-3 wrap-12'>
                <h2 className='resume-title'>Interests</h2>
              </div>
              <div className='wrap-lg-9 wrap-12'>
                <p>
                  User Experience, Accessibility, Web Standards, Automatization,
                  Technology, Simplicity, Coffee, Food, Art, Music, Movies,
                  Traveling, Plants, Goats, Chickens.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <SiteFooter /> */}
        {/* </SmoothScroll> */}
      </motion.main>
    </PageLayout>
  );
};

export default Resume;
