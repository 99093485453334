import { useState, useEffect, useRef } from 'react';
import { TweenMax } from 'gsap';

const SmoothCursor = () => {
  // const lerp = (a, b, n) => (1 - n) * a + n * b;
  const mouseRef = useRef(null);
  const mouseInnerRef = useRef(null);
  const mouseWrapperRef = useRef(null);
  const [showcursor, setShowCursor] = useState(false);

  const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.clientX || e.clientY) {
      posx = e.clientX;
      posy = e.clientY;
    }
    return { x: posx, y: posy };
  };

  useEffect(() => {
    setShowCursor(true);
    const handlemousemove = (e) => {
      const { x, y } = getMousePos(e);
      if (mouseRef.current !== null || mouseInnerRef.current !== null) {
        const outerCircle = mouseRef.current.getBoundingClientRect();
        const innerCircle = mouseInnerRef.current.getBoundingClientRect();
        TweenMax.to([mouseRef.current, mouseInnerRef.current], {
          opacity: 1,
          delay: 0.2,
        });
        TweenMax.to('.sg-cursor--outer', 0.2, {
          x: x - outerCircle.width / 2,
          y: y - outerCircle.height / 2,
        });
        TweenMax.set('.sg-cursor--inner', {
          x: x - innerCircle.width / 2,
          y: y - innerCircle.height / 2,
        });
      }
    };
    const move = 25;

    const handleslidermouseenter = function (e) {
      // const { offsetX: x, offsetY: y } = e;
      // const { offsetWidth: width, offsetHeight: height } = this;
      // let xMove = (x / width) * (move * 2) - move;
      // let yMove = (y / height) * (move * 2) - move;

      // TweenMax.to(e.target, {
      //   x: xMove,
      //   y: yMove,
      // });
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.add('cursor-player');
      }
    };
    const handleslidermouseleave = function (e) {
      TweenMax.to(e.target, {
        x: 0,
        y: 0,
      });
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.remove('cursor-player');
      }
    };

    const handleTXTmouseenter = function (e) {
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.add('cursor-txt');
      }
    };
    const handleTXTmouseleave = function (e) {
      TweenMax.to(e.target, {
        x: 0,
        y: 0,
      });
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.remove('cursor-txt');
      }
    };

    const handlelinkmousenter = function (e) {
      const { offsetX: x, offsetY: y } = e;
      const { offsetWidth: width, offsetHeight: height } = this;
      let xMove = (x / width) * (move * 2) - move;
      let yMove = (y / height) * (move * 2) - move;

      TweenMax.to(e.target, {
        x: xMove,
        y: yMove,
      });
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.add('cursor-link');
      }
    };
    const handlelinkmouseleave = function (e) {
      TweenMax.to(e.target, {
        x: 0,
        y: 0,
      });
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.remove('cursor-link');
      }
    };

    const handlebuttonmousenter = function (e) {
      if (mouseWrapperRef.current) {
        mouseWrapperRef.current.classList.add('cursor-link');
      }
    };
    const buttons = document.querySelectorAll('.d-button');
    // eslint-disable-next-line no-unused-vars
    const links = document.querySelectorAll('.site-link');
    const logo = document.querySelectorAll('.site-logo-img');
    const txtlink = document.querySelectorAll('.link');
    const player = document.querySelector('.player');

    buttons.forEach((item) => {
      item.addEventListener('mousemove', handlebuttonmousenter);
      item.addEventListener('mouseleave', handlelinkmouseleave);
    });
    if (player) {
      player.addEventListener('mousemove', handleslidermouseenter);
      player.addEventListener('mouseleave', handleslidermouseleave);
    }
    txtlink.forEach((el) => {
      el.addEventListener('mousemove', handleTXTmouseenter);
      el.addEventListener('mouseleave', handleTXTmouseleave);
    });

    [logo].forEach((el) => {
      el.forEach((item) => {
        item.addEventListener('mousemove', handlelinkmousenter);
        item.addEventListener('mouseleave', handlelinkmouseleave);
      });
    });
    document.addEventListener('mousemove', handlemousemove);

    // return () => {
    //   // document.removeEventListener("mousemove", handlemousemove);
    //   const buttons = document.querySelectorAll(".d-button");
    //   // const links = document.querySelectorAll(".site-link");
    //   // const logo = document.querySelectorAll(".site-logo");
    //   buttons.forEach((item) => {
    //     item.removeEventListener("mousemove", handlebuttonmousenter);
    //     item.removeEventListener("mouseleave", handlelinkmouseleave);
    //   });
    //   // [links, logo].forEach((el) => {
    //   //   el.forEach((item) => {
    //   //     item.removeEventListener("mousemove", handlelinkmousenter);
    //   //     item.removeEventListener("mouseleave", handlelinkmouseleave);
    //   //   });
    //   // });
    // };
  }, [showcursor]);

  return (
    <div className='sg-cursor' ref={mouseWrapperRef}>
      <div className='sg-cursor--outer' ref={mouseRef}>
        <svg>
          <circle
            cx='20'
            cy='20'
            r='15'
            stroke='white'
            strokeWidth='2'
            fill='none'></circle>
        </svg>
      </div>
      <div className='sg-cursor--inner' ref={mouseInnerRef}>
        <span className='dot'></span>
        <span className='sg-cursor--txt'>Music</span>

        <span className='sg-cursor--click'>Click</span>
      </div>
    </div>
  );
};

export default SmoothCursor;
