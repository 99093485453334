import { useEffect, useContext } from 'react';
import useSound from 'use-sound';
import PageLayout from '../../../components/Hoc/PageLayout';
// import SmoothScroll from "../../../components/SmoothScroll/SmoothScroll";
import { UiContext } from '../../../core/ui-context/UiContext';
const SuprimUiComp = () => {
  const { toggle_audio, state } = useContext(UiContext);
  const [hoverplay, { stop: hoverstop }] = useSound(
    '/assets/sounds/hover1.mp3'
  );
  const handleaudiotoggle = () => {
    toggle_audio();
  };

  const [play, { stop, isPlaying }] = useSound('/assets/sounds/ambience.mp3', {
    volume: 0.5,
    interrupt: true,
    soundEnabled: state.playAudio,
    loop: true,
  });

  useEffect(() => {
    if (state.playAudio === false) {
      stop();
    } else {
      if (state.preload) {
        play();
      }
    }
    return () => {
      stop();
    };
  }, [state.playAudio, state.preload, isPlaying, stop, play]);
  return (
    <PageLayout>
      <main exit={{ opacity: 0 }} className='resume-content'>
        {/* <SmoothScroll> */}
        <section className='work-detail--intro sp'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-6'>
                <h2>Suprim Ui Components</h2>
                <p>
                  supirm-ui is a ui implementation for styling React, NextJs App
                  originating from Suprim's design. The design of the Suprim UI
                  is concise and aesthetic feeling, this is an important reason
                  for popular of Suprim. Now you can use them at will in React
                  App.
                </p>
                <a
                  className='site-link'
                  href='https://suprim.org.np/guide/introduction'
                  rel='noreferrer'
                  target='_blank'>
                  Visit Site
                </a>
              </div>
              <div className='wrap-lg-3'>
                <h2>Position</h2>
                <p>Full Stack Development</p>
                <div className='spacer'></div>
                <div className='spacer'></div>
                <h2>Date</h2>
                <p>2018</p>
              </div>
              <div className='wrap-lg-3'>
                <h2>Technologies</h2>
                <ul>
                  <li>
                    <sub>01</sub>
                    React
                  </li>
                  <li>
                    <sub>02</sub>
                    TypeScript
                  </li>
                  <li>
                    <sub>03</sub>
                    Styled Components
                  </li>
                  <li>
                    <sub>03</sub>
                    Rollup & Babel
                  </li>
                  <li>
                    <sub>03</sub>
                    Lint & Husky Staged
                  </li>
                  <li>
                    <sub>04</sub>
                    Gsap
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='work-detail--fluid'>
          <img src='/assets/img/slide/3.jpg' alt='' />
        </section>
        <section className='work-detail--content'>
          <div className='wrapper'>
            <figure>
              <figcaption>Home</figcaption>
              <img src='/assets/img/suprimui/home.jpg' alt='as' />
            </figure>
            <figure>
              <figcaption>Components</figcaption>
              <img src='/assets/img/suprimui/comp.jpg' alt='login' />
            </figure>
            <figure>
              <figcaption>Icons</figcaption>
              <img src='/assets/img/suprimui/icons.jpg' alt='login' />
            </figure>
          </div>
        </section>
        {/* </SmoothScroll> */}
      </main>
      <section className='footer-music'>
        <div className='wrapper'>
          <div
            className={`music--btn ${isPlaying ? 'active' : ''} `}
            onMouseEnter={hoverplay}
            onMouseLeave={hoverstop}>
            <button
              type='button'
              className='player'
              onClick={handleaudiotoggle}>
              <span className='center'></span>
              <span className='pulse'></span>
              <span className='pulse2'></span>
            </button>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default SuprimUiComp;
