import gsap from "gsap";
import { useContext, useEffect, useState } from "react";
import { UiContext } from "../../core/ui-context/UiContext";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

const Preloader = () => {
  const [lockbody, setLockBody] = useState(true);
  const { ui_preloaded, state } = useContext(UiContext);

  useLockBodyScroll(lockbody);

  useEffect(() => {
    if (!state.preload) {
      const preloaderTimeline = gsap.timeline();
      preloaderTimeline
        .to(".line-h3 h3", {
          opacity: 1,
          y: "0px",
          // duration: 0.5,
          delay: 1,
        })
        .to(".line-h3 h3", {
          opacity: 0,
          y: "-80px",
          // duration: 0.5,
          delay: 1,
          onComplete: () => {
            gsap.to(".preloader", {
              y: "-100%",
              duration: 0.5,
              // delay: 0.5,
              onStart: () => {
                setLockBody(false);
              },
              onComplete: () => {
                ui_preloaded();
              },
            });
            gsap.to(".preloader--trailer", {
              y: "-100%",
              delay: 0.2,
            });
          },
        });
    } else {
      setLockBody(false);
    }
  }, [state.preload, ui_preloaded, setLockBody]);

  if (state.preload) return null;
  return (
    <>
      <div className="preloader">
        <div className="preloader--content">
          <div className="preloader--title">
            <div className="line-h3">
              <h3>Welcome</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="preloader--trailer"></div>
    </>
  );
};

export default Preloader;
