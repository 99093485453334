import { useEffect, useContext } from 'react';
import useSound from 'use-sound';
import PageLayout from '../../../components/Hoc/PageLayout';
// import SmoothScroll from "../../../components/SmoothScroll/SmoothScroll";
import { UiContext } from '../../../core/ui-context/UiContext';

const Grihabhoomi = () => {
  const { toggle_audio, state } = useContext(UiContext);
  const [hoverplay, { stop: hoverstop }] = useSound(
    '/assets/sounds/hover1.mp3'
  );
  const handleaudiotoggle = () => {
    toggle_audio();
  };

  const [play, { stop, isPlaying }] = useSound('/assets/sounds/ambience.mp3', {
    volume: 0.5,
    interrupt: true,
    soundEnabled: state.playAudio,
    loop: true,
  });

  useEffect(() => {
    if (state.playAudio === false) {
      stop();
    } else {
      if (state.preload) {
        play();
      }
    }
    return () => {
      stop();
    };
  }, [state.playAudio, state.preload, isPlaying, stop, play]);

  return (
    <PageLayout>
      <main exit={{ opacity: 0 }} className='resume-content'>
        {/* <SmoothScroll> */}
        <section className='work-detail--intro sp'>
          <div className='wrapper'>
            <div className='wrapper-row'>
              <div className='wrap-lg-6'>
                <h2>Griha Bhoomi</h2>
                <p>
                  grihabhoomi.com is the leading online real estate marketplace
                  in Nepal, currently listing tens of thousands of properties
                  for sale and rent all over Kathmandu, Pokhara and rest the
                  country. Thousands of Nepalese connect every day on
                  grihabhoomi to find a new place to live in Nepal. From rural
                  listings to new property developments, we list everything.
                </p>
                <a
                  className='site-link'
                  href='http://www.grihabhoomi.com/'
                  rel='noreferrer'
                  target='_blank'>
                  Visit Site
                </a>
              </div>
              <div className='wrap-lg-3'>
                <h2>Position</h2>
                <p>Front End Development</p>
                <div className='spacer'></div>
                <div className='spacer'></div>
                <h2>Date</h2>
                <p>2019</p>
              </div>
              <div className='wrap-lg-3'>
                <h2>Technologies</h2>
                <ul>
                  <li>
                    <sub>01</sub>
                    React
                  </li>
                  <li>
                    <sub>02</sub>
                    NextJs
                  </li>
                  <li>
                    <sub>03</sub>
                    Styled Components
                  </li>
                  <li>
                    <sub>04</sub>
                    Gsap
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='work-detail--fluid'>
          <img src='/assets/img/slide/1.jpg' alt='' />
        </section>
        <section className='work-detail--content'>
          <div className='wrapper'>
            <figure>
              <figcaption>Home</figcaption>
              <img src='/assets/img/grihabhoomi.jpg' alt='as' />
            </figure>
            <figure>
              <figcaption>Login</figcaption>
              <img src='/assets/img/grihabhoomi/login.jpg' alt='login' />
            </figure>
            <figure>
              <figcaption>Map View</figcaption>
              <img src='/assets/img/grihabhoomi/mapview.jpg' alt='as' />
            </figure>
            <figure>
              <figcaption>Product Detail</figcaption>
              <img src='/assets/img/grihabhoomi/product-detail.jpg' alt='as' />
            </figure>
          </div>
        </section>
        {/* </SmoothScroll> */}
      </main>
      <section className='footer-music'>
        <div className='wrapper'>
          <div
            className={`music--btn ${isPlaying ? 'active' : ''} `}
            onMouseEnter={hoverplay}
            onMouseLeave={hoverstop}>
            <button
              type='button'
              className='player'
              onClick={handleaudiotoggle}>
              <span className='center'></span>
              <span className='pulse'></span>
              <span className='pulse2'></span>
            </button>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default Grihabhoomi;
