import { motion } from "framer-motion";
const PageTrans = (props) => {
  // function cover() {
  //   const t1 = gsap.timeline({
  //     onComplete: () => {
  //       uncover(t1);
  //     },
  //   });
  //   console.log(t1, "cover");
  //   t1.to("#svgtrans path", {
  //     opacity: 1,
  //     duration: 0,
  //     attr: {
  //       d: "M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z",
  //     },
  //   })
  //     .to("#svgtrans path", {
  //       opacity: 1,
  //       duration: 1,
  //       attr: {
  //         d: "M 10,0 L 10,10 L 10,10 C 10,10 5,10 5,5 C 5,0 10,0 10,0 Z",
  //       },
  //     })
  //     .to("#svgtrans path", {
  //       opacity: 1,
  //       duration: 1,
  //       attr: {
  //         d: "M 10,0 L 10,10 L 0,10 C 0,10 0,10 0,5 C 0,0 0,0 0,0 Z",
  //       },
  //     });
  //   function uncover() {
  //     t1.to("#svgtrans path", {
  //       opacity: 1,
  //       duration: 1,
  //       delay: 0.8,
  //       attr: {
  //         d: "M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z",
  //       },
  //     });
  //   }
  // }

  // const pathVar = {
  //   intial: {
  //     d: "M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z",
  //   },
  //   animate: {
  //     transition: {
  //       duration: 1,
  //       ease: [0.83, 0, 0.17, 1],
  //     },
  //     d: "M 10,0 L 10,10 L 10,10 C 10,10 5,10 5,5 C 5,0 10,0 10,0 Z",
  //   },
  //   exit:,
  // };
  return (
    <>
      <section className="page-trans">
        <motion.svg
          width="102%"
          height="102%"
          viewBox="0 0 10 10"
          id="svgtrans"
          preserveAspectRatio="none"
        >
          <motion.path
            fill="#111"
            {...props}
            exit={{
              transition: {
                duration: 2,
                ease: [0.83, 0, 0.17, 1],
              },
              d: [
                "M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z",
                "M 10,0 L 10,10 L 10,10 C 10,10 5,10 5,5 C 5,0 10,0 10,0 Z",
                "M 10,0 L 10,10 L 0,10 C 0,10 0,10 0,5 C 0,0 0,0 0,0 Z",
                "M 10,0 L 10,10 L 0,10 C 0,10 0,10 0,5 C 0,0 0,0 0,0 Z",
                "M 10,0 L 10,10 L 0,10 C 0,10 5,10 5,5 C 5,0  0,0 0,0 Z",
                "M 10,0 L 10,10 L 10,10 C 10,10 10,10 10,5 C 10,0 10,0 10,0 Z",
              ],
            }}
          ></motion.path>
        </motion.svg>
      </section>
    </>
  );
};

export default PageTrans;
